<script lang="ts">
  import Button from './Button.svelte';
  import CheckBox from './CheckBox.svelte';
  import ExpandablePanel from './ExpandablePanel.svelte';
  import CrossIcon from './icons/CrossIcon.svelte';
  import Popup from './Popup.svelte';
  import type { Config } from './types';
  import { i18n } from './utils';

  interface Props {
    config: Config;
    choices: string[];
    onShowWelcome: () => void;
    onAllowAll: () => void;
    onRejectAll: () => void;
    onConfirmChoices: (choices: string[]) => void;
  }

  let {
    config,
    choices = $bindable(),
    onShowWelcome,
    onAllowAll,
    onRejectAll,
    onConfirmChoices,
  }: Props = $props();

  function update(category: string, checked: boolean) {
    if (checked) choices = choices.concat(category);
    else choices = choices.filter((choice) => choice != category);
  }
</script>

<Popup {config}>
  <div class="wrapper">
    <div class="close-cross" onclick={onShowWelcome}><CrossIcon /></div>
    <div class="headline1">{i18n(config.title)}</div>

    <div class="scrollable">
      <div class="headline2">{i18n(config.settingsHeader)}</div>
      <div class="description">{i18n(config.settingsText)}</div>

      <div class="buttons-top">
        <Button on:click={onAllowAll}>
          {i18n(config.buttons.allowAll)}
        </Button>
      </div>

      <div class="headline2">{i18n(config.settingsManage)}</div>

      <div class="panels">
        {#each config.categories as { name, title, text }}
          {#if name === 'essential'}
            <ExpandablePanel title={i18n(title)}>
              {#snippet action()}
                <span class="always-active">{i18n(config.alwaysActive)}</span>
              {/snippet}
              {@html i18n(text)}
            </ExpandablePanel>
          {:else}
            <ExpandablePanel title={i18n(title)}>
              {#snippet action()}
                <CheckBox
                  checked={choices.includes(name)}
                  onChange={(checked) => update(name, checked)}
                />
              {/snippet}
              {i18n(text)}
            </ExpandablePanel>
          {/if}
        {/each}
      </div>
    </div>

    <div class="buttons-bottom">
      <div class="grow"></div>
      <Button lite={true} on:click={onRejectAll}>
        {i18n(config.buttons.rejectAll)}
      </Button>
      <div class="gap"></div>
      <Button on:click={() => onConfirmChoices(choices)}>
        {i18n(config.buttons.confirmChoices)}
      </Button>
    </div>
  </div>
</Popup>

<style>
  .wrapper {
    display: flex;
    flex-direction: column;
    max-height: 100%;
  }

  .close-cross {
    position: absolute;
    top: 0;
    right: 0;
    padding: 10px;
    cursor: pointer;
  }

  .headline1 {
    font-size: 2em;
    font-weight: 600;
  }

  .headline2 {
    font-size: 1.3em;
    font-weight: 600;
    margin: 20px 0;
  }

  .description {
    font-size: 1em;
    margin: 20px 0;
    line-height: 1.3;
  }

  .always-active {
    color: var(--hey-accent-color);
    user-select: none;
    white-space: nowrap;
  }

  .buttons-bottom {
    margin-top: 15px;
    text-align: right;
  }

  .scrollable {
    flex-grow: 1;
    overflow: auto;
  }

  .buttons-bottom {
    display: flex;
    flex-direction: row;
  }

  .gap {
    width: 16px;
  }

  .grow {
    flex-grow: 1;
  }

  .panels {
    border: 1px solid #eaeaea;
    border-radius: 5px;
    /* To hide the .header background beyond the border-radius */
    overflow: hidden;
  }
</style>
